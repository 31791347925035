<template>
  <ul class="w-full text-left pl-4 text-sm uppercase">
    <li class="flex items-center justify-center space-x-3 py-1.5" v-for="(league, key) in leaguesToShow" :key="key">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-green-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        /></svg><span class="flex-1">{{league.title}}</span>
    </li>
    <li class="mr-4">
      <button
        v-on:click="showingOnlySome = !showingOnlySome"
        class="w-full flex bg-white underline items-center justify-center text-xs p-3 px-6 font-bold uppercase  text-blue-700"
      >
        <template v-if="showingOnlySome">See all...</template>
        <template v-else>See less...</template>
      </button>
    </li>
  </ul>
</template>

<script>
import Leagues from './../data/Leagues'
export default {
  name: "LeageList",
  props: ["free"],
  data() {
    return {
      showingOnlySome: true,
      leagues: []
    };
  },
  mounted() {
    this.leagues = Leagues;
  },
  computed: {
    leaguesToShow() {
      return !this.showingOnlySome ? this.leagues : this.leagues.slice(0,5);
    }
  }
};
</script>

<style></style>
